import HttpClient from "../lib/http-client";
import { getTTL } from "../views/users/list/common-utils";

/**
 * @memberof Services
 */
class AccountService extends HttpClient {
  /**
   *
   * @param {string} username
   * @param {string} password
   * @returns {object}
   */
  async login(username, password) {
    const data = await this.http.post("/auth/login", {
      username,
      password,
    });
    return data;
  }

  /**
   *
   * @param {string} email
   * @returns {object}
   */
  async sendResetPassword(values) {
    const data = await this.http.post("/auth/reset/send", values);
    return data;
  }

  /**
   *
   * @param {string} userId
   * @returns {boolean}
   */
  async resendInvite(userId) {
    const apiUrl = `/auth/invite/${userId}`;
    return await this.http.post(apiUrl);
  }

  /**
   * @param {string} email
   * @param {string} token
   * @returns {object}
   */
  async validateResetPassword(email, token) {
    const params = {
      token,
      email,
    };
    const data = await this.http.get(`/auth/reset/password`, { params });
    return data;
  }

  /**
   *
   * @param {string} token
   * @param {string} password
   * @returns {object}
   */
  async resetPassword(payload) {
    const data = await this.http.post("/auth/reset/password", payload);
    return data;
  }

  /**
   *
   * @returns {object}
   */
  async impersonate() {
    const data = await this.http.get("client-services/impersonate/login");
    return data;
  }

  /**
   * Obtains user information
   * @returns {object}
   */
  async ssoLogin() {
    const coreAuthUrl = process.env.CORE_AUTH_URL;
    const url = `${coreAuthUrl}/oauth2/login`;
    const data = await this.http.get(url);
    return data;
  }

  async ssoLogout(slo_url, callbackUrl) {
    if (!callbackUrl) {
      callbackUrl = new URL("/login", document.baseURI).href;
    }

    const ssoLogoutUrl = `${slo_url}?target=${encodeURIComponent(callbackUrl)}`;

    window.location.href = ssoLogoutUrl;
  }

  async samlLogout(organizationCode) {
    // Attempt saml logout with given organization first
    if (organizationCode) {
      console.log(JSON.stringify(organizationCode));
      const url = "saml/logout/" + organizationCode;
      const data = await this.http.get(url);

      return data;
    }
  }

  async deleteCodes(payload) {
    await this.http.delete("/auth/login-codes", {
      data: payload,
    });
  }

  /**
   *
   * @returns {array}
   */
  async createLoginCodes(payload) {
    return await this.http
      .post("/auth/login-codes", payload)
      .then((response) =>
        response?.map((value) => ({ ...value, ttl: getTTL(value.expiry) }))
      );
  }

  async fetchMostRecentCodes(params, filterFn) {
    return await this.http
      .get(`/auth/login-codes`, {
        params,
      })
      .then((response) => (filterFn ? filterFn(response) : response));
  }

  /**
   *
   * @returns {URL} redirectUrl
   */
  async proprofsAuth(payload) {
    const data = await this.http.post("/auth/proprofs/login", payload);
    return data;
  }

  /**
   *
   * @returns {object}
   */
  async verify_self_signup() {
    const data = await this.http.get("/auth/self_signup/verify");
    return data;
  }
}

export default AccountService;
