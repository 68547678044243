import { useStoreState } from "easy-peasy";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  NotificationStack,
  NotificationType,
} from "@transfr-inc/dashboard-components";

import { ProductDropDownList } from "../../../../components/custom/dropdowns/product-dropdown-list";
import { SimpleSimPassButton } from "../../../../components/sim-pass-button";
import { TraineeClassroomCeTab } from "./trainee.classroom.ce.tab";
import { TraineeClassroomTrekTab } from "./trainee.classroom.trek.tab";
import { TraineeClassroomTsTab } from "./trainee.classroom.ts.tab";

import container from "../../../../container";
import { Product } from "../../../../models";
import { filterClassroomLoginCodesByUserId } from "../../../../services";
import { hasAnyProducts } from "../../list/common-utils";

import { useHistoryState, useNotificationStack } from "../../../../lib/hooks";
import { trackView } from "../../../../lib/event-trackers";
import { ViewUser } from "../../../../models/tracking/events";

export function TraineeClassroomTab({
  trainee,
  classroom,
  organization,
  onRemoveTrainee,
  removeTraineeEnabled,
}) {
  const { classroomId, products } = classroom;
  const { accountService } = container;

  const { selectedProduct } = useLocation()?.state || {};
  const [selectedProductId, setSelectedProductId] = useState(
    selectedProduct ?? products[0]
  );
  const [generatingSimPass, setGeneratingSimPass] = useState(false);
  const [deletingSimPass, setDeletingSimPass] = useState(false);
  const [simPass, setSimPass] = useState();

  const showSimPass = useMemo(
    () =>
      !trainee.isAnonymous &&
      hasAnyProducts(products, [Product.CE, Product.TRK]),
    [products]
  );

  const { addStateProps } = useHistoryState();

  const { notifications, closeNotification, addNotification } =
    useNotificationStack();

  useEffect(() => {
    // retrieve and display any existing, active sim pass
    accountService
      .fetchMostRecentCodes({
        user_id: trainee.userId,
        classroom_id: classroomId,
        product: Product.CE,
      })
      .then((codes) => filterClassroomLoginCodesByUserId(codes, trainee.userId))
      .then((code) => {
        setSimPass(code);
      });
  }, []);

  useEffect(() => {
    const products = classroom.products;
    const isTSClass = products.includes(Product.TS);
    const isTrekWithCE = products.includes(Product.CE);

    if (isTSClass || isTrekWithCE) {
      trackView(
        ViewUser,
        "Trainee Details",
        classroom.name,
        String(isTSClass ? Product.TS : Product.CE),
        classroom.classroomId,
        trainee.userId
      );
    }
  }, [classroom]);

  async function generateSimPass() {
    setGeneratingSimPass(true);
    try {
      const payload = {
        userIds: [trainee.userId],
        classroomId: classroomId,
        product: Product.CE,
      };

      const [result] = await accountService.createLoginCodes(payload);
      setSimPass(result);
    } catch {
      addNotification({
        type: NotificationType.error,
        message: "Something went wrong. Please try again.",
      });
    }
    setGeneratingSimPass(false);
  }

  async function deleteSimPass() {
    setDeletingSimPass(true);
    try {
      const payload = {
        userId: trainee.userId,
        classroomId: classroomId,
        product: Product.CE,
      };

      await accountService.deleteCodes(payload);
      setSimPass();
    } catch {
      addNotification({
        type: NotificationType.error,
        message: "Something went wrong. Please try again.",
      });
    }
    setDeletingSimPass(false);
  }

  const renderClassroomTab = (classroomProduct) => {
    switch (classroomProduct) {
      case Product.TS:
        return (
          <TraineeClassroomTsTab
            trainee={trainee}
            classroom={classroom}
            organization={organization}
            onRemoveTrainee={onRemoveTrainee}
            removeTraineeEnabled={removeTraineeEnabled}
          />
        );
      case Product.CE:
        return (
          <TraineeClassroomCeTab
            trainee={trainee}
            classroom={classroom}
            onRemoveTrainee={onRemoveTrainee}
            removeTraineeEnabled={removeTraineeEnabled}
          />
        );
      case Product.TRK:
        return (
          <TraineeClassroomTrekTab
            trainee={trainee}
            classroom={classroom}
            onRemoveTrainee={onRemoveTrainee}
            removeTraineeEnabled={removeTraineeEnabled}
          />
        );
    }
  };

  const onProductSelected = ({ id }) => {
    setSelectedProductId(id);
    addStateProps({ selectedProduct: id });
  };

  const simPassProps = {
    deletingSimPass,
    generatingSimPass,
    simPass,
    user: trainee,
    onClick: generateSimPass,
    onDelete: deleteSimPass,
  };

  return (
    <>
      <div className="classroom-tab-header">
        {products.length > 1 && (
          <div className="product-select">
            <h2 className="product-select-label">Product</h2>
            <ProductDropDownList
              products={products}
              className="classroom-product-dropdown"
              onProductSelected={onProductSelected}
              defaultProduct={selectedProductId}
            />
          </div>
        )}
        {showSimPass && <SimpleSimPassButton {...simPassProps} />}
      </div>
      {renderClassroomTab(selectedProductId)}
      <NotificationStack
        notifications={notifications}
        onClose={(index) => closeNotification({ index })}
        closable
        animated
      />
    </>
  );
}
