import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionHeaderName,
  TextTooltip,
} from "@transfr-inc/dashboard-components";
import { UnitLessonAccordion } from "./unit-lesson-accordion";
import "./course-unit-accordion.scss";

export function CourseUnitAccordion({
  unit,
  isExpanded,
  index,
  responsiveStyles,
  hideExpandProperties = false,
  courseId,
}) {
  const allCompleted = unit?.masteries?.every((mastery) => mastery.completed);

  return (
    <Accordion
      className="course-unit-accordion"
      isOpen={isExpanded}
      hideExpandProperties={hideExpandProperties}
    >
      <div className="course-unit-accordion-header">
        <AccordionHeader>
          <AccordionHeaderName>
            <div className="course-unit-accordion-header-name">
              <div>
                <div className="unit-title-badge">
                  <div>
                    Unit {index}: {unit?.title}
                  </div>
                  <span className="badge">{unit?.lessons?.length || 0}</span>
                </div>
              </div>
              <div className="completed-cell">
                {allCompleted ? (
                  <>
                    <FontAwesomeIcon icon="fa-solid fa-circle-check"></FontAwesomeIcon>
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon="fa-regular fa-circle"></FontAwesomeIcon>
                  </>
                )}
              </div>
            </div>
          </AccordionHeaderName>
        </AccordionHeader>
      </div>
      <AccordionBody>
        <div className="lesson-accordion-container">
          {unit?.lessons?.map((lesson, index) => (
            <UnitLessonAccordion
              key={lesson.id}
              lesson={lesson}
              index={index + 1}
              isExpanded={index === 0}
              responsiveStyles={responsiveStyles}
              courseId={courseId}
            />
          ))}
        </div>
      </AccordionBody>
    </Accordion>
  );
}
