import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./lessons-masteries.table.scss";
import {
  BasicCell,
  Column,
  ColumnOptions,
  ColumnType,
  DataTable,
  TimeDataCell,
} from "@transfr-inc/dashboard-components/data-table";
import clsx from "clsx";
import { Link } from "@transfr-inc/dashboard-components";

export default function LessonsMasteriesTable({
  lesson,
  responsiveStyles,
  courseId,
}) {
  const [tableData, setTableData] = useState([]);

  const columns = [
    new Column(
      "title",
      "Activity",
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: true,
        customFunction: (value, row) => {
          return (
            <div className="activity-title-icon-cell">
              <div
                className={clsx(
                  "activity-icon",
                  `content-type-${row.contentType}`
                )}
              >
                {row.contentType === 1 ? (
                  <FontAwesomeIcon icon="fa-regular fa-head-side-goggles" />
                ) : (
                  <FontAwesomeIcon icon="fa-regular fa-computer" />
                )}
              </div>
              <Link className="activity-title">{value}</Link>
            </div>
          );
        },
      })
    ),
    new Column(
      "attempts",
      "Attempts",
      ColumnType.COUNT,
      new ColumnOptions({ sortingEnabled: true })
    ),
    new Column(
      "timeSpent",
      "Time",
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: true,
        customFunction: TimeDataCell,
      })
    ),
    new Column(
      "status",
      "Status",
      ColumnType.BASIC,
      new ColumnOptions({ sortingEnabled: true })
    ),
    new Column(
      "completed",
      "Complete",
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: true,
        customFunction: (value) => {
          return (
            <div className="completed-cell">
              {value.completed ? (
                <>
                  <FontAwesomeIcon icon="fa-solid fa-circle-check"></FontAwesomeIcon>
                </>
              ) : (
                <BasicCell />
              )}
            </div>
          );
        },
      })
    ),
  ];

  useEffect(() => {
    if (!lesson || !lesson.activities) return;
    const data = lesson.activities.map((activity) => {
      return {
        ...activity,
        status: activity.completed ? "Completed" : "Not Completed",
        time: activity.timeSpent ?? 0,
        attempts: activity.attempts ?? 0,
        completed: activity.completed ?? false,
      };
    });

    setTableData(data);
  }, [lesson]);

  return (
    <DataTable
      className={clsx("lessons-masteries-table", responsiveStyles)}
      columns={columns}
      data={tableData}
      keyFields={["id"]}
      responsiveStyles={responsiveStyles}
      rowHeightConfig={{ large: 54, medium: 160, small: 280 }}
    />
  );
}
