import React, { useEffect, useState } from "react";
import { useApiRequest } from "../../../../lib/http-client";
import container from "../../../../container";
import { Loader } from "@transfr-inc/dashboard-components";
import { useContainerQuery } from "@transfr-inc/dashboard-components/container-query";
import "./course.tab.scss";
import { CourseUnitAccordion } from "./course-unit-accordion";
import { useStoreState } from "easy-peasy";
import { CourseTopSection } from "./course-top-section";

const query = {
  "small-panel": {
    minWidth: 320,
    maxWidth: 850,
  },
  "medium-panel": {
    minWidth: 700,
    maxWidth: 900,
  },
  "large-panel": {
    minWidth: 900,
  },
};

export function CourseTab({ id, name }) {
  const { courseService, insightsService } = container;
  const [courseData, setCourseData] = useState();
  const [params, containerRef] = useContainerQuery(query);

  const { response: data, loading: loadingCourseData } = useApiRequest(() =>
    courseService.getCourseDetails(id).then(setCourseData)
  );

  const { currentUser } = useStoreState((store) => store.account);
  const { userId } = currentUser;
  // const userId = "dc0dd80e-9b66-4bae-9395-83da8ed299c1"; // HARDCODE
  const [masteryScores, setMasteryScores] = useState("");
  const [masteryOOHScores, setMasteryOOHScores] = useState("");

  const { loading: loadingIHMastery, response: masteryIH } = useApiRequest(() =>
    insightsService.getAllUserSimulationMastery(userId)
  );

  const { loading: loadingOohMastery, response: masteryOOH } = useApiRequest(
    () => insightsService.getCourseOohMastery(userId, id)
  );

  const mapMasteries = (masteryList) => {
    if (!masteryList) return null;
    let masteries = {};
    masteryList.map((mastery) => {
      masteries[mastery["activity_id"]] = mastery["mastery_score"];
    });
    return masteries;
  };

  const mapOohMasteries = (masteryList) => {
    if (!masteryList) return null;
    // scores not implemented yet
    let masteries = {};
    masteryList.map((mastery) => {
      masteries[mastery["object_id"]] = mastery["latestTimestamp"];
    });
    return masteries;
  };

  const checkCompleted = (activity) => {
    if (activity.simulation && activity.simulation_id in masteryScores) {
      activity.mastery_score = masteryScores[activity.simulation_id];
      return true;
    } else if (!activity.simulation && activity.id in masteryOOHScores) {
      console.log(masteryOOHScores[activity.id]);
      activity.completed_at = masteryOOHScores[activity.id];
      return true;
    }
    return false;
  };

  useEffect(() => {
    setMasteryScores(mapMasteries(masteryIH));
    setMasteryOOHScores(mapOohMasteries(masteryOOH));
  }, [masteryIH, masteryOOH]);

  return (
    <>
      {loadingCourseData && <Loader overlay />}
      <div>
        <CourseTopSection course={courseData} />
      </div>
      <div className="course-content-container" ref={containerRef}>
        <div className="course-units-container">
          {courseData?.units?.map((unit, index) => (
            <CourseUnitAccordion
              key={unit.id}
              unit={unit}
              index={index + 1}
              responsiveStyles={params}
              isExpanded={index === 0}
              courseId={id}
            />
          ))}
        </div>
      </div>
    </>
  );
}
